import React from "react"
import Layout from '../components/layout/layout'
import HeroWithTitle from "../components/publicaciones/heroWithTitle"
import InfoCuidad from "../components/info-cuidad/info-cuidad"
import HeroImage1 from "../images/heroMaestros.png"

let heroWithTitle = {
  img: HeroImage1,
  title: "Monterrey",
}

let data = {
  courseContent: [
    " Nuevo super cuadernillos de estudio 2020, basados en el último examen ¡lo más actual del mercado! ",
    "10 horas de clases presenciales semanales",
    " Asesoría personalizada después de clases",
    " Clases en vivo online semanales ¡repasa desde tu casa!",
    " Mas de 30 simuladores del examen EXANI-II ¡lo más real del mercado! Practica con más de 4000 reactivos tipo examen de admisión",
    "Platicas de orientación vocacional con profesionales expertos Maestros capacitados en cada área diferente del examen",
    " Plan de seguimiento personalizado en áreas de mejoras",
  ],
  courseDate: "Lunes 20 de enero del 2020",
  courseDuration: "20 semanas (200 horas)",
  courseGrups: [
    {
      name: "Grupo 1",
      schedule: [
        "Lunes de 9:00 am a 12:30 pm",
        "Miércoles de 9:00 am a 12:30 pm ",
        "Viernes de 9:00 am a 12:00 pm",
      ],
      percentage: 50,
    },
    {
      name: "Grupo 2",
      schedule: [
        "Lunes de 9:00 am a 12:30 pm",
        "Miércoles de 9:00 am a 12:30 pm ",
        "Viernes de 9:00 am a 12:00 pm",
      ],
      percentage: 70,
    },
    {
      name: "Grupo 3",
      schedule: [
        "Lunes de 9:00 am a 12:30 pm",
        "Miércoles de 9:00 am a 12:30 pm ",
        "Viernes de 9:00 am a 12:00 pm",
      ],
      percentage: 70,
    },
  ],
  courseInscription: "$500 pesos M.N.",
  couseWeeklyPayment: "$500 pesos M.N.",
  location: "Centro Mitras, Monterrey, Nuevo León.",
}

export default () => (
  <Layout>
    <HeroWithTitle {...heroWithTitle} />
    <InfoCuidad {...data} />
  </Layout>
)
