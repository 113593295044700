import React from "react"
import infoStyle from "./info-cuidad.module.scss"
import heroImg from "../../images/cuidadesHero.jpg"

import iconPerson from "../../images/15.png"
import iconCalendar from "../../images/16.png"
import iconClock from "../../images/17.png"
import iconSheet from "../../images/18.png"
import iconPig from "../../images/19.png"
import iconPin from "../../images/20.png"
import iconPhone from "../../images/22.png"
import iconEmail from "../../images/21.png"

export default function infoCuidad(props) {
  return (
    <div>
      <div className={infoStyle.imgContainer}>
        <img src={heroImg} className={infoStyle.img} alt="heroImg" />
      </div>
      <div className={infoStyle.container}>
        <div className={infoStyle.mainTitleContainer}>
          {" "}
          Información del Curso
        </div>
        <div className={infoStyle.whiteContainer}>
          <div className={infoStyle.mainTextContainer}>
            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconPerson} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}>
                  {" "}
                  Incluye el Curso{" "}
                </h2>
                <ul className={infoStyle.ul}>
                  {props.courseContent.map(content => {
                    return (
                      <li className={infoStyle.li}>
                        <p className={infoStyle.p}>{content}</p>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>

            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconCalendar} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}>
                  {" "}
                  Inicio de Curso{" "}
                </h2>
                <p className={infoStyle.descrition}>{props.courseDate}</p>
              </div>
            </div>

            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconClock} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}>
                  {" "}
                  Duración de Curso{" "}
                </h2>
                <p className={infoStyle.descrition}>{props.courseDate}</p>
              </div>
            </div>

            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconSheet} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}>
                  {" "}
                  Horarios y grupos disponibles{" "}
                </h2>
                {props.courseGrups.map(grup => {
                  let temp = grup.schedule.map(sche => {
                    return (
                      <li className={infoStyle.li}>
                        <p className={infoStyle.p}>{sche}</p>
                      </li>
                    )
                  })
                  let lenghtBar = "" + grup.percentage + "%"
                  return (
                    <div>
                      <ul>{temp}</ul>
                      <div className={infoStyle.sliderMainContainer}>
                        <div className={infoStyle.sliderContainer}>
                          <div
                            className={infoStyle.slider}
                            style={{ width: lenghtBar }}
                          >
                            <p className={infoStyle.progressNumLeft}>
                              {grup.percentage}%
                            </p>
                          </div>
                        </div>
                        <img
                          src={iconPerson}
                          className={infoStyle.iconSlider}
                          alt="icon"
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className={infoStyle.descriptionMainContainer}>
              <div />
              <div />
              <h3 className={infoStyle.sideText}>10 horas por semana</h3>
            </div>

            <div className={infoStyle.descriptionMainContainerSecondTitle}>
              <p className={infoStyle.secondTitle}>Finaliza</p>
              <div />
              <p className={infoStyle.pSecond}>
                De uno a dos días antes del examen de admisión
              </p>
            </div>
            <hr className={infoStyle.hr} />

            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconPig} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}> Inversión </h2>
                <p className={infoStyle.descrition}>
                  <span className={infoStyle.span}>Inscripción: </span>
                  {props.courseInscription}
                </p>
                <p className={infoStyle.descrition}>
                  <span className={infoStyle.span}>Pago Semanal: </span>
                  {props.couseWeeklyPayment}
                </p>
              </div>
            </div>

            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconPin} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}> Ubicación </h2>
                <p className={infoStyle.descrition}>{props.location}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={infoStyle.whiteContainer}>
          <div className={infoStyle.mainTextContainer}>
            <div className={infoStyle.descriptionContactMainContainer}>
              <div />
              <div />
              <h1 className={infoStyle.titleDescription}>Contacto</h1>
            </div>

            <div className={infoStyle.descriptionContactMainContainer}>
              <img src={iconPhone} className={infoStyle.icon} alt="icon" />
              <div />
              <p className={infoStyle.contactDescription}> 234234234 </p>
            </div>

            <div className={infoStyle.descriptionContactMainContainer}>
              <img src={iconEmail} className={infoStyle.icon} alt="icon" />
              <div />
              <p className={infoStyle.contactDescription}>
                {" "}
                uniacces@gmail.com{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
